import React from "react"

import logo from "./../img/logo.svg"

export default function Header({step}) {
    return (
        <>
            <header className={step === "welcome" ? "App-header App-header--welcome" : "App-header"}>
                <img src={logo} className="App-logo" alt="DPSCD Logo" />
                <h1>
                    Connected Futures
                    <br />
                    Device Pickup
                </h1>
            </header>
        </>
    )
}
