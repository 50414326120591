import React from 'react'

export default function Equipment({asset}) {
    return (
        <li key={asset.DPS_LAPTOP_SERIAL}>
            Device Serial: {asset.DPS_LAPTOP_SERIAL}<br />
            IMEI: {asset.DPS_IMEI_ID}<br />
            Serial: {asset.DPS_TTK_SERIAL}<br />
            SIM Serial: {asset.DPS_SIM_SERIAL}
        </li>
    )
}
