import React, { useState, useEffect } from "react"

export default function ReservationForm({
    initialReservationNumber,
    initialLastName,
    initialLaneNumber,
    initialLocation,
    handleGetLocations,
    handleReservationFormSubmit,
    handleSetStep,
}) {
    const [reservationNumber, setReservationNumber] = useState(initialReservationNumber)
    const [reservationNumberStatus, setReservationNumberStatus] = useState("")
    const [reservationNumberErrorMsg, setReservationNumberErrorMsg] = useState("")
    const [reservationLastName, setReservationLastName] = useState(initialLastName)
    const [laneNumber, setLaneNumber] = useState(initialLaneNumber)
    const [locations, setLocations] = useState([])
    const [location, setLocation] = useState(initialLocation)
    const [disabled, setDisabled] = useState("disabled")

    const MAX_LENGTH = 6
    const API_BASE_URL = "https://api2.detroitk12.org/connected-futures-reservations-api"
    const CORS_MODE = "cors" // cors or no-cors
    const MAX_LANES = 25

    const laneNumbers = [...Array(MAX_LANES).keys()] // make an array of lane numbers

    useEffect(() => {
        const getLocations = async () => {
            const locations = await handleGetLocations()
            setLocations(locations)
        }
        getLocations()
    }, [handleGetLocations])

    useEffect(() => {
        if (reservationNumber.length > MAX_LENGTH) {
            setReservationNumberStatus("error")
        } else {
            setReservationNumberStatus("none")
        }
    }, [reservationNumber, MAX_LENGTH])

    useEffect(() => {
        setDisabled(
            !reservationNumber ||
                reservationNumberStatus === "error" ||
                !reservationLastName ||
                !laneNumber ||
                !location
                ? "disabled"
                : ""
        )
    }, [reservationNumber, reservationLastName, laneNumber, location, reservationNumberStatus])

    const setReservationHandler = reservationNumber => {
        setReservationNumber(reservationNumber.toUpperCase().replace(/\W/g, ""))

        if (reservationNumber.length > MAX_LENGTH) {
            setReservationNumberStatus("error")
        } else {
            setReservationNumberStatus("none")
        }
    }
    const validateReservationHandler = async reservationNumber => {
        if ((await validateReservationNumber(reservationNumber)) === true) {
            setReservationNumberStatus("success")
        } else {
            setReservationNumberStatus("error")
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setDisabled("disabled")
        handleSetStep("loading")

        if (!reservationNumber || reservationNumber.length !== MAX_LENGTH) {
            setReservationNumberStatus("error")
            handleSetStep("reservation")
        }

        if (reservationNumber && reservationLastName) {
            if (await validateReservationNumber(reservationNumber)) {
                handleReservationFormSubmit(reservationNumber, reservationLastName, laneNumber, location)
            }
        }
    }

    const validateReservationNumber = async reservationNumber => {
        if (reservationNumber) {
            // console.info("Validating response…")

            if (reservationNumber.length !== MAX_LENGTH) {
                // console.warn("Not valid!")
                setReservationNumberErrorMsg("Please use a maximum of 6 characters")
                return false
            }

            try {
                const response = await fetch(`${API_BASE_URL}/reservations/${reservationNumber}`, {
                    mode: CORS_MODE,
                })
                if (response.status === 200) {
                    const data = await response.json()
                    setLocation(data.DPS_PICKUP_LOC_ID)

                    if (data.DPS_HOUSEHOLD_ID) {
                        // console.info("Found!")
                        setReservationNumberErrorMsg("")
                        return true
                    } else {
                        console.warn("Not Found!")
                        setReservationNumberErrorMsg(
                            "We could not find the reservation number. Please find a volunteer to help."
                        )
                        return false
                    }
                } else {
                    console.warn("Not found")
                    setReservationNumberErrorMsg(
                        "We could not find the reservation number. Please find a volunteer to help."
                    )
                    return false
                }
            } catch (err) {
                console.error("Error", err)
                setReservationNumberErrorMsg(
                    "We could not find the reservation number. Please find a volunteer to help."
                )
                return false
            }
        } else {
            return false
        }
    }

    return (
        <>
            <p>
                First, lets find your reservation. Please fill out the form below and then click the submit button. All
                fields are required.
            </p>
            <form onSubmit={handleSubmit}>
                <label htmlFor="reservationNumber">Reservation Number</label>
                <input
                    type="text"
                    name=""
                    id="reservationNumber"
                    spellCheck="false"
                    autoComplete="off"
                    maxLength="6"
                    value={reservationNumber}
                    onChange={e => setReservationHandler(e.target.value)}
                    onBlur={e => validateReservationHandler(e.target.value)}
                    className={
                        reservationNumberStatus === "error"
                            ? "input--error"
                            : reservationNumberStatus === "success"
                            ? "input--success"
                            : ""
                    }
                />
                <span className="errorMsg">{reservationNumberErrorMsg}</span>
                <label htmlFor="lastName">What is the Last Name on the Reservation?</label>
                <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    spellCheck="false"
                    autoComplete="off"
                    maxLength="30"
                    value={reservationLastName}
                    onChange={e => setReservationLastName(e.target.value)}
                ></input>
                <label htmlFor="laneNumber">What Lane Number are you in?</label>
                <select
                    name="laneNumber"
                    id="laneNumber"
                    value={laneNumber}
                    onChange={e => setLaneNumber(e.target.value)}
                >
                    <option value="">Choose One</option>
                    <option value="walkup" key="walkup">
                        Walk-up
                    </option>
                    {laneNumbers.map(number => {
                        number++
                        return (
                            <option value={number} key={`lane${number}`}>
                                {number}
                            </option>
                        )
                    })}
                </select>

                <label htmlFor="location">Current Pickup Location</label>
                <select name="location" id="location" value={location} onChange={e => setLocation(e.target.value)}>
                    <option value="">Choose One</option>
                    {locations.map(location => (
                        <option value={location.DPS_PICKUP_LOC_ID} key={location.DPS_PICKUP_LOC_ID}>
                            {location.NAME}
                        </option>
                    ))}
                </select>

                <button id="submitButton" type="submit" className="btn--primary" disabled={disabled}>
                    Submit
                </button>
            </form>
        </>
    )
}
