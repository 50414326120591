import React, { useEffect, useRef } from "react"
import Equipment from "./Equipment"

export default function OrderForm({ reservationNumber, reservationRecord, handleUpdateOrder }) {

    const updateActive = useRef(null)

    useEffect(() => {
        const interval = setInterval(async () => {
            await handleUpdateOrder(reservationNumber)
        }, 10000)

        //cleanup
        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        // console.log("reservationRecord: ", reservationRecord)
        // get the list of assets and display on page
        if (reservationRecord.STATUS_CODE === "DLV") {
            updateActive.current = false
        }
    }, [reservationRecord])

    return (
        <>
            <h2>Order {reservationNumber}</h2>
            <p>
                {reservationRecord.STATUS_CODE === "CMP" ? (
                    <>
                        Your order is ready!{" "}
                        <span role="img" aria-label="celebrate emoji">
                            🎉
                        </span>{" "}
                        We’ll be with you shortly.
                    </>
                ) : (
                    <>
                        Our volunteers are putting your order together now.{" "}
                        <span role="img" aria-label="smiley face emoji">
                            😀
                        </span>{" "}
                        Details will be displayed below.
                    </>
                )}
            </p>
            <p>Please have one of the following available:</p>
            <ul>
                <li>Drivers license</li>
                <li>State ID</li>
                <li>Student ID with your reservation letter</li>
            </ul>
            {reservationRecord.equipment && reservationRecord.equipment.data.length > 0 ? (
                <>
                    <h3>Order Summary</h3>
                    <ul>
                        {reservationRecord.equipment.data.map(asset => (
                            <Equipment asset={asset} key={asset.DPS_LAPTOP_SERIAL} />
                        ))}
                    </ul>
                </>
            ) : (
                <></>
            )}
        </>
    )
}
