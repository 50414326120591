import React, { useState, useEffect } from "react"
import * as moment from "moment"

import Header from "./components/Header"
import ReservationForm from "./components/ReservationForm"
import VehicleForm from "./components/VehicleForm"
import OrderForm from "./components/OrderForm"
import FeedbackForm from "./components/FeedbackForm"
import Equipment from "./components/Equipment"

import "./css/App.css"

const API_BASE_URL = "https://api2.detroitk12.org/connected-futures-reservations-api"
const CORS_MODE = "cors"

function App() {
    const [step, setStep] = useState("welcome")

    const [reservationNumber, setReservationNumber] = useState("")
    const [lastName, setLastName] = useState("")
    const [laneNumber, setLaneNumber] = useState("")
    const [location, setLocation] = useState("")
    const [, setColor] = useState("")
    const [, setMake] = useState("")
    const [, setModel] = useState("")
    const [orderDetails, setOrderDetails] = useState({})

    useEffect(() => {
        // scroll to the top whenever the step changes
        window.scrollTo(0, 0)
    }, [step])

    const confirmLastNamesMatch = (name1, name2) => {
        return name1.toLowerCase().trim() === name2.toLowerCase().trim()
    }


    const handleReservationFormSubmit = async (reservationNumber, lastName, laneNumber, location) => {
        setStep("loading")
        setReservationNumber(reservationNumber)
        setLastName(lastName)
        setLaneNumber(laneNumber)
        setLocation(location)

        // Check the status
        let response = await fetch(`${API_BASE_URL}/reservations/${reservationNumber}`, {
            method: "GET",
            mode: CORS_MODE,
            cache: "no-cache",
        })
        const reservationRecordCheck = await response.json()

        if (reservationRecordCheck.STATUS_CODE === "DLV") {
            // Reservation is already marked as completed
            setStep("duplicateError")
        } else if (reservationRecordCheck.STATUS_CODE === "RDY" || reservationRecordCheck.STATUS_CODE === "CMP") {
            if (!confirmLastNamesMatch(lastName, reservationRecordCheck.LAST_NAME)) {
                await handleSetValidationError()
            } else {
                setStep("processing")
            }
        }
        else {
            // Update reservation record with current lane number
            let timestamp = moment().format("DD-MMM-YY hh.mm.ss.SSSSSSSSS A").toUpperCase()
            let body = {
                DPS_LANE: laneNumber,
                START_DTTM: timestamp,
                DPS_ACT_PICKUP_LOC: location,
            }
            if (laneNumber === "walkup") {
                body = { ...body, STATUS_CODE: "RDY" }
            } else {
                body = { ...body, STATUS_CODE: "NEW" }
            }
            response = await fetch(`${API_BASE_URL}/reservations/${reservationNumber}`, {
                method: "PATCH",
                mode: CORS_MODE,
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })

            const reservationRecord = await response.json()

            // Confirm last  names match
            if (confirmLastNamesMatch(lastName, reservationRecord.LAST_NAME)) {
                if (laneNumber === "walkup") {
                    setStep("processing")
                } else {
                    setStep("vehicle")
                }
            } else {
                await handleSetValidationError()
                console.warn("Oh noes! Names do not match. ☹")
            }
        }
    }

    const handleVehicleFormSubmit = async (color, make, model) => {
        setColor(color)
        setMake(make)
        setModel(model)
        setStep("processing")

        await fetch(`${API_BASE_URL}/reservations/${reservationNumber}`, {
            method: "PATCH",
            mode: CORS_MODE,
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                CAR_MODEL_DESCR: model,
                MAKE_AND_MODEL: make,
                COLORNAME: color,
                STATUS_CODE: "RDY",
            }),
        })
    }

    const handleUpdateOrder = async reservationNumber => {
        const reservationOrderResponse = await fetch(`${API_BASE_URL}/reservations/${reservationNumber}`)
        const reservationOrderData = await reservationOrderResponse.json()

        const equipmentResponse = await fetch(`${API_BASE_URL}/equipment?DPS_RESERVATION_ID=${reservationNumber}`)
        const equipmentData = await equipmentResponse.json()

        setOrderDetails({ ...reservationOrderData, equipment: { ...equipmentData } })

        if (reservationOrderData.STATUS_CODE === "DLV") {
            setStep("feedback")
        }
    }

    const handleSetValidationError = async () => {
        setStep("validationError")
        if (reservationNumber) {
            await fetch(`${API_BASE_URL}/reservations/${reservationNumber}`, {
                method: "PATCH",
                mode: CORS_MODE,
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    STATUS_CODE: "ERR",
                }),
            })
        }
    }

    const handleGetLocations = async () => {
        try {
            let response = await fetch(`${API_BASE_URL}/locations`, {
                method: "GET",
                mode: CORS_MODE,
                cache: "no-cache",
            })
            const locations = await response.json()
            return locations.data
        } catch (err) {
            console.error("There was a problem with your fetch operation:", err)
            setStep("serverIssue")
            return [
                {
                    NAME: "Cannot retrieve locations",
                },
            ]
        }
    }

    const handleSubmitFeedback = async ({ process, transportation, feedback, rating }) => {
        setStep("loading")

        // PeopleSoft uses WE8ISO8859P15 charset instead of UTF-8; replacing common characters
        feedback = feedback.replace("’", "'").replace("‘", "'").replace("”", '"').replace("“", '"')

        await fetch(`${API_BASE_URL}/reservations/${reservationNumber}`, {
            method: "PATCH",
            mode: CORS_MODE,
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                EP_RATING_DESCR: process,
                TRANSPORT: transportation,
                COMMENT1: feedback,
                RATING_DESCR: rating,
            }),
        })
        setStep("complete")
    }

    const handleSetStep = newStep => {
        setStep(newStep)
    }

    return (
        <div className={step === "loading" ? "App loading" : "App"}>
            <Header step={step} />
            {step === "welcome" ? (
                <>
                    <h2>Welcome</h2>
                    <p>
                        We’re excited you’re here! Please have your reservation number ready. If you do not know your
                        reservation number, please find a volunteer to help.
                    </p>
                    <p>
                        Do not refresh your browser or use the back button during this process. (It will cause you to
                        start over.)
                    </p>
                    <button className="btn--primary" onClick={() => setStep("reservation")}>
                        Begin
                    </button>
                    <footer>
                        <p>
                            Detroit Public Schools Community District does not discriminate on the basis of race, color,
                            national origin, sex, sexual orientation, transgender identity, disability, age, religion,
                            height, weight, citizenship, marital or family status, military status, ancestry, genetic
                            information, or any other legally protected category, in its educational programs and
                            activities, including employment and admissions opportunities.
                        </p>
                    </footer>
                </>
            ) : step === "reservation" ? (
                <ReservationForm
                    initialReservationNumber={reservationNumber}
                    initialLastName={lastName}
                    initialLaneNumber={laneNumber}
                    initialLocation={location}
                    handleGetLocations={handleGetLocations}
                    handleReservationFormSubmit={handleReservationFormSubmit}
                    handleSetStep={handleSetStep}
                />
            ) : step === "vehicle" ? (
                <VehicleForm handleVehicleFormSubmit={handleVehicleFormSubmit} handleSetStep={handleSetStep} />
            ) : step === "processing" ? (
                <OrderForm
                    reservationNumber={reservationNumber}
                    reservationRecord={orderDetails}
                    handleUpdateOrder={handleUpdateOrder}
                />
            ) : step === "validationError" ? (
                <>
                    <h2>Oops!</h2>
                    <p>The system could not validate your reservation.</p>
                    <p>Please stay in your lane and find a volunteer to help.</p>
                    <button onClick={() => setStep("reservation")}>Try again</button>
                </>
            ) : step === "duplicateError" ? (
                <>
                    <h2>Oops!</h2>
                    <p>
                        Order <span className="orderNumber">{reservationNumber}</span> has already been completed.
                    </p>
                    <p>Please stay in your lane and find a volunteer to help.</p>
                    <button onClick={() => setStep("reservation")} className="btn--secondary">
                        Try again
                    </button>
                </>
            ) : step === "feedback" ? (
                <FeedbackForm handleSubmitFeedback={handleSubmitFeedback} />
            ) : step === "complete" ? (
                <>
                    <h2>Thank you</h2>
                    <p>Your order is complete. Have a great day!</p>
                    {orderDetails.equipment ? (
                        <>
                            <h3>Order Summary</h3>
                            <ul>
                                {orderDetails.equipment.data.map(asset => (
                                    <Equipment asset={asset} key={asset.DPS_LAPTOP_SERIAL} />
                                ))}
                            </ul>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            ) : step === "serverIssue" ? (
                <>
                    <h2>We’ll be right back</h2>
                    <p>Our servers are having issues at the moment.</p>
                    <button className="btn--secondary" onClick={() => setStep("welcome")}>
                        Try Again
                    </button>
                </>
            ) : (
                <>
                    <h2>Sit tight</h2>
                    <p>Processing…</p>
                </>
            )}
        </div>
    )
}

export default App
