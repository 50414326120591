import React, { useState } from "react"
import Ratings from "react-ratings-declarative"

export default function FeedbackForm({ handleSubmitFeedback }) {
    const [process, setProcess] = useState(" ")
    const [transportation, setTransportation] = useState(" ")
    const [feedback, setFeedback] = useState(" ")
    const [rating, setRating] = useState(0)

    const handleSubmit = async e => {
        e.preventDefault()
        const allTheFeedback = {
            process, // process: process
            transportation,
            feedback,
            rating,
        }
        handleSubmitFeedback(allTheFeedback)
    }

    return (
        <>
            <h2>Thank you</h2>
            <p>Please tell us about your experience.</p>
            <form onSubmit={handleSubmit}>
                {/* Process */}
                <fieldset>
                    <label>Was the process for pickup up your new device easy to understand?</label>
                    <label
                        htmlFor="process--yes"
                        className={process === "Yes" ? "input--radio__checked" : "input--radio"}
                    >
                        <input
                            type="radio"
                            name="process"
                            id="process--yes"
                            value="Yes"
                            checked={process === "Yes"}
                            onChange={e => setProcess(e.target.value)}
                        />
                        Yes
                    </label>
                    <label
                        htmlFor="process--no"
                        className={process === "No" ? "input--radio__checked" : "input--radio"}
                    >
                        <input
                            type="radio"
                            name="process"
                            id="process--no"
                            value="No"
                            checked={process === "No"}
                            onChange={e => setProcess(e.target.value)}
                        />
                        No
                    </label>
                </fieldset>

                {/* Transportation */}
                <fieldset>
                    <label>How easy was it for you to find transportation here today?</label>
                    <label
                        htmlFor="transportation--very-easy"
                        className={transportation === "Very Easy" ? "input--radio__checked" : "input--radio"}
                    >
                        <input
                            type="radio"
                            name="transportation"
                            id="transportation--very-easy"
                            value="Very Easy"
                            checked={transportation === "Very Easy"}
                            onChange={e => setTransportation(e.target.value)}
                        />
                        Very Easy
                    </label>
                    <label
                        htmlFor="transportation--easy"
                        className={transportation === "Easy" ? "input--radio__checked" : "input--radio"}
                    >
                        <input
                            type="radio"
                            name="transportation"
                            id="transportation--easy"
                            value="Easy"
                            checked={transportation === "Easy"}
                            onChange={e => setTransportation(e.target.value)}
                        />
                        Easy
                    </label>
                    <label
                        htmlFor="transportation--neutral"
                        className={transportation === "Neutral" ? "input--radio__checked" : "input--radio"}
                    >
                        <input
                            type="radio"
                            name="transportation"
                            id="transportation--neutral"
                            value="Neutral"
                            checked={transportation === "Neutral"}
                            onChange={e => setTransportation(e.target.value)}
                        />
                        Just right
                    </label>
                    <label
                        htmlFor="transportation--somewhat-difficult"
                        className={transportation === "Somewhat Difficult" ? "input--radio__checked" : "input--radio"}
                    >
                        <input
                            type="radio"
                            name="transportation"
                            id="transportation--somewhat-difficult"
                            value="Somewhat Difficult"
                            checked={transportation === "Somewhat Difficult"}
                            onChange={e => setTransportation(e.target.value)}
                        />
                        Somewhat Difficult
                    </label>
                    <label
                        htmlFor="transportation--very-difficult"
                        className={transportation === "Very Difficult" ? "input--radio__checked" : "input--radio"}
                    >
                        <input
                            type="radio"
                            name="transportation"
                            id="transportation--very-difficult"
                            value="Very Difficult"
                            checked={transportation === "Very Difficult"}
                            onChange={e => setTransportation(e.target.value)}
                        />
                        Very Difficult
                    </label>
                </fieldset>

                {/* Feedback */}

                <label htmlFor="feedback">Feedback</label>
                <textarea
                    name="feedback"
                    id="feedback"
                    cols="30"
                    rows="4"
                    value={feedback}
                    onChange={e => setFeedback(e.target.value)}
                />

                {/* Rating */}

                <label htmlFor="rating">Overall Rating</label>
                <div className="rating" role="presentation" id="rating">
                    <Ratings
                        rating={rating}
                        widgetEmptyColors="#6c6c6c"
                        widgetRatedColors="#ffcc00"
                        widgetHoverColors="#ffcc00"
                        widgetDimensions="40px"
                        widgetSpacings="1px"
                        changeRating={setRating}
                    >
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                    </Ratings>
                </div>

                <button className="btn--primary">Submit Feedback</button>
            </form>
        </>
    )
}
