import React, { useState, useEffect } from "react"

import vehicleData from "../data/vehicles.json"

export default function VehicleForm({ handleVehicleFormSubmit, handleSetStep }) {
    const [color, setColor] = useState("")
    const [make, setMake] = useState("")
    const [model, setModel] = useState("")
    const [disabled, setDisabled] = useState("disabled")
    const [makes, setMakes] = useState([])
    const [models, setModels] = useState([])

    useEffect(() => {
        setDisabled(!color || !make || !model ? "disabled" : "")
    }, [color, make, model])

    useEffect(() => {
        const models = vehicleData.results.filter(vehicle => vehicle.Make === make)

        const uniqueModels = []
        models.forEach(vehicle => {
            uniqueModels.push(vehicle.Model)
        })
        uniqueModels.sort()

        setModels([...new Set(uniqueModels)])
    }, [make])

    const handleSubmit = e => {
        e.preventDefault()
        setDisabled("disabled")
        handleSetStep("loading")

        if (color && make && model) {
            handleVehicleFormSubmit(color, make, model)
        }
    }

    const getVehicleData = () => {
        const uniqueMakes = []
        vehicleData.results.forEach(vehicle => {
            uniqueMakes.push(vehicle.Make)
        })
        setMakes([...new Set(uniqueMakes)])
    }
    useEffect(() => {
        getVehicleData()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <p>Please provide some details about your vehicle to help us serve you better.</p>

            <form onSubmit={handleSubmit}>
                <label htmlFor="vehicleColor">Vehicle Color</label>
                <input
                    type="text"
                    name="vehicleColor"
                    id="vehicleColor"
                    spellCheck="false"
                    autoComplete="off"
                    value={color}
                    onChange={e => setColor(e.target.value)}
                />

                <label htmlFor="vehicleMake">Vehicle Make</label>
                <select name="vehicleMake" id="vehicleMake" value={make} onChange={e => setMake(e.target.value)}>
                    <option value="">Choose One</option>
                    {makes.map(make => (
                        <option value={make} key={make}>
                            {make}
                        </option>
                    ))}
                </select>

                <label htmlFor="vehicleModel">Vehicle Model</label>
                <select name="vehicleModel" id="vehicleModel" value={model} onChange={e => setModel(e.target.value)}>
                    <option value="">Choose One</option>
                    {models.map(model => (
                        <option value={model} key={model}>
                            {model}
                        </option>
                    ))}
                </select>

                <button type="submit" className="btn--primary" disabled={disabled}>
                    Submit Vehicle Information
                </button>
            </form>
        </>
    )
}
